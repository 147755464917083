import { Link } from "gatsby"
import React from "react"

function Sidebar({}) {
  return( 
      <div class="md:mt-4">
        <h1 class="divider font-bold mb-3 pb-2 text-xl text-center md:text-left">ARTICLES RÉCENTS</h1>
        <div>
          <ul class="text-sm">
         <li class="divider pb-2 mb-4 hover:text-gray-400 cursor-pointer leading-6 flex items-center gap-3">
           <p>&#10148;</p>
           <Link to={`/actualite/borne-tactile`}><p>BORNE TACTILE – PLAN DE TRAVAIL TACTILE</p></Link></li> 
         <li class="divider pb-2 mb-4 hover:text-gray-400 cursor-pointer leading-6 flex items-center gap-3">
         <p>&#10148;</p>
          <Link to={`/actualite/chargeur-sans-fil-integre`}><p> Chargeur sans fil intégré dans le plan en Solid Surface et iPhone avec coque spéciale</p></Link></li> 
         <li class="divider pb-2 mb-4 hover:text-gray-400 cursor-pointer leading-6 flex items-center gap-3">
         <p>&#10148;</p>
          <Link to={`/actualite/LG-Hausys`}><p>LG Hausys dévoile, en avant première, les couleurs Velvet</p></Link></li> 
         <li class="divider pb-2 mb-4 hover:text-gray-400 cursor-pointer leading-6 flex items-center gap-3">
         <p>&#10148;</p>
         <Link to={`/actualite/lg-hausys-est-de-retour-sur-retail`}><p> LG Hausys est de retour sur Retail Design Expo avec HI-MACS Structura®</p></Link></li> 
         <li class="divider pb-2 mb-4 hover:text-gray-400 cursor-pointer leading-6 flex items-center gap-3">
         <p>&#10148;</p>
         <Link to={`/actualite/produits-dinterieur-et-collections`}> <p>Porduits d’intérieur & collections</p></Link>
         </li> 
         <li class="divider pb-2 mb-4 hover:text-gray-400 cursor-pointer leading-6 flex items-center gap-3">
           <p>&#10148;</p>
           <Link to={`/actualite/HI-MACS-Structura`}><p> Qu’est-ce que HI-MACS Structura®?</p></Link>
         </li>
          </ul>
        </div>

      </div>
  )
}

export default Sidebar