import React from "react"
import Sidebar from '../../components/sidebar'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import lgHausys2 from "../../assets/img/lghausys2.jpg"

const lgHausys2Page = () => (
  <Layout>
    <SEO title="chargeur sans fil intégré" />
 
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <div class="grid grid-cols-1 gap-2 md:col-span-3 md:my-5 mx-3"> {/** first element */}
             <h1 class="text-2xl font-bold mb-8 divider">LG Hausys est de retour sur Retail Design Expo avec HI-MACS Structura®</h1>
            <img class="mb-0 mx-auto w-full" src={lgHausys2} alt="LG Hausys" />
            <h1 class="text-2xl text-center font-bold mb-2 divider">LG Hausys est de retour sur Retail Design Expo avec HI-MACS Structura®</h1>
            <p class="text-justify mt-3">
                <span class="font-bold">HI-MACS®</span>, le solid surface de LG Hausys, sera présenté à l’occasion de l’édition 2017 de <span class="font-bold">Retail Design Expo, les 8 & 9 mai à Londres</span>. Pouvant recouvrir n’importe quel design ou forme, extrêmement robuste, et peu exigeant en termes d’entretien, HI-MACS® est un matériau parfaitement adapté au secteur commercial. De nombreuses enseignes de ce secteur, comme par exemple le magasin Zara dans le centre commercial Westfield ou encore les boutiques Yo Sushi outlets et Bershka en Belgique, ont été séduites par les avantages de <span class="font-bold">HI-MACS®</span> et en particulier par sa finition moderne de pierre acrylique aux possibilités illimitées. Pour tous ceux qui cherchent à imprégner leur marque dans l’univers de l’entreprise, il est possible d’appliquer des logos ou de les graver sur le matériau lui même.
                    Nouveauté 2017, <span  class="font-bold">HI-MACS</span> Structura®, fait une entrée remarquée de part sa tridimentionalité qui ne manque pas d’impressionner. Lancé en mars, il a déjà suscité un vif intérêt auprès des architectes et autres concepteurs. <a href="http://himacs.eu/fr/structura" class="text-red-700">HI-MACS Structura®</a> est disponible en 10 finitions standards, mais peut également être fabriqué sur-mesure avec tous les motifs désirés, repoussant encore les frontières du design de surface.
                    <a href="http://himacs.eu/fr/lg-hausys-launches-velvet-en-avant-premiere-les-couleurs-velvet-4-nouvelles-teintes-de-la-gamme-solid-de-hi" class="text-red-700">Les quatre nouvelles couleurs Velvet</a> seront également en vedette. Ces nouvelles venues dans la gamme Solids s’inspirent de la beauté du monde naturel, en particulier des pigments organiques et des matériaux tels que le cuir et l’argile brute.
                    Constitué de minéraux, d’acrylique et de pigments naturels, <span class="font-bold">HI-MACS®</span> offre une surface lisse, non poreuse et sans joints apparents, contribuant ainsi à répondre aux plus hautes normes de qualité, d’esthétique, de fabrication, de fonctionnalité et d’hygiène. C’est la somme de ces éléments qui donne vie à un matériau moderne, nettement supérieur aux matériaux conventionnels résistant à l’épreuve du temps.
                    Venez nous rendre visite au stand G31.  </p>

       </div>

       <div>
         <Sidebar />
       </div>
    </div>
  </Layout>
  )
 
  export default lgHausys2Page
